.App {
  text-align: center;
}


html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  margin: 0;
  padding: 0;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */

  width: 100%;
  height: 100%;
  background-color: #E5E5E5;
}

@keyframes slideleft {
  from {
      background-position: 0%;
  }
  
  to {
      background-position: 90000%;
  }
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.formBlock {
  margin-top: 20px;
  margin-bottom: 20px;
}

.adminBlock {
  min-height: 200px;
}
